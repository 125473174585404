@import "../../style/custonVariable";
.MianContent{
    padding: 15px;
    .search{
        position: relative;
        // top: 50px;
        // max-width: 420px;
        // margin: auto;
        background: rgba(255,255,255,0.8);
        padding: 15px;
        border-radius: 2px;
        border: 1px solid #ececec;
        .Buttons{
            .downlineButton{
                background-color: $color__primary;
                border-color: $color__primary;
                font-size: 13px;
                line-height: 1.5;
                i{
                    position: relative;
                    float: left;
                    width: 34px;
                    height: 34px;
                    margin: -7px -16px;
                    margin-right: 12px;
                    line-height: 34px;
                    text-align: center;
                    background-color: rgba(0,0,0,0.1);
                    border-radius: 2px 0 0 2px;
                }
            }
        }
        .searchForm{
            display: grid;
            align-items: end;
            grid-template-columns: 2fr 1fr;
            justify-content: end;
            .searchButton{
                margin-right: 2px;
                border-radius: 2px;
                outline: 0!important;
                color: $color-white!important;
                background-color: $color-primary!important;
                border-color: $color-primary;
                
            }
            .m0{
                margin-bottom: 0px !important;
                font-size: 13px;
            }

        }
    }
    .treeContent{
        position: relative;
        overflow: auto;
        min-height: 800px;

        .treeChart{
            overflow: visible;
            display: inline-block;
            min-width: 100%;
            margin-top: 30px;
            transform-origin: 0 0 0!important;
            .main{
                margin-top: 26px;
                .mainTable{
                    zoom: 1;
                    transform-origin: 0px 0px;
                    width: 100%;
                    background-color: transparent;
                    border-spacing: 0;
                    border-collapse: collapse;
                    &.width50{
                        td{
                            width: 50%;
                        }
                    }
                    td{
                        text-align: center;
                        vertical-align: top;
                        padding: 0;
                    }
                    .node{
                        cursor: default;
                        margin: -5px 5px;
                        font-size: 12px!important;
                        color: #ca00c3;
                        display: inline-block;
                        width: auto;
                        margin: -5px 25px;
                        z-index: 10;
                        overflow: hidden;
                        word-break: break-all;
                        &.treeNode{
                            width: max-content;
                            min-width: 15px;
                        }
                        .profileImage{
                            max-width: 100%;
                            width: 74px;
                            height: 74px;
                            border: 2px solid #c8d5d8;
                            border-radius: 50%;
                            padding: 4px;
                            background: #fff;
                            cursor: pointer;
                        }
                    }
                }
            }
        }
    }
}
.line{
    height: 24px;
    width: 2px!important;
    overflow: hidden;
    font-size: 0;
}
.down{
    background-color: #c8d5d8 !important;
    margin: 0 auto;
}
.left{
    border-right: 2px solid #c8d5d8 !important;
}
.right{
    border-left: 2px solid #fff !important;
}
.top{
    border-top: 2px solid #c8d5d8 !important;
}
.dmeoName{
    max-width: 100%;
    width: auto;
    line-height: 1;
    padding: 3px 3px 4px 3px;
    background-color: #5c519f;
    border-radius: 2px;
    margin-top: 5px;
    margin-bottom: 0;
    color: #fff;
}
.dmeoNameBlocked{
    max-width: 100%;
    width: auto;
    line-height: 1;
    padding: 3px 3px 4px 3px;
    background-color: red;
    border-radius: 2px;
    margin-top: 5px;
    margin-bottom: 0;
    color: #fff;
}
.addButtonImg{
    // max-width:  100%;
    width: 45px;
    padding: 2px;
    filter: grayscale(0);
    border: 2px solid #c8d5d8;
    border-radius: 50%;
    background: #fff;
    cursor: pointer;
    &.marginWidth {
        max-width: none;
        margin: -5px 40px;
    }
}
.downIcon{
    cursor: pointer;
    width: 16px;
    height: 16px;
    margin-top: 5px;
    opacity: 0.6;
    transition: all 0.1s ease;
}
.tooltipContainer{
    // background-color: #40b7e5;
    // min-height: 300px;
    width: 224px;
    padding: 0;
}
.arrow{
    height: 1rem;
    position: absolute;
    width: 1rem;
    &::before{
        position: absolute;
        content: "";
        display: block;
        width: 0;
        height: 0;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 10px solid #ca00c3;
        left: 50%;
        margin-left: -10px;
        top: -10px;
    }
    // background-color: red !important;
}

.expand{
    padding: unset;
    margin: auto;
    height: 140px;
}
.expandRight{
    position: absolute;
    height: 0px !important;
    border-left: none !important;
    // margin-left: 74px;
    margin-top: 0;
    width: 500.5px;
    left: 50%;
}
.expandLeft{
    position: absolute;
    height: 0px !important;
    border-left: none !important;
    // margin-left: 74px;
    margin-top: 0;
    width: 500.5px;
    right: 50%;
}
.expandDown{
    margin-left: -2px;
    left: 50%;
    position: absolute;
    margin-top: 0;
    top: -87%;
}
.upIcon{
    position: absolute;
    left: 50%;
    margin-top: 23px;
    margin-left: -8px;
    cursor: pointer;
}
.positionRelative{
    position: relative;
}

//sponsor tree style
.SponsorContent{
    position: relative;
    overflow: auto;
    min-height: 700px;
    .treeChart{
        overflow: visible;
        display: inline-block;
        min-width: 100%;
        margin-top: 30px;
        transform-origin: 0 0 0!important;
        .main{
            margin-top: 26px;
            .mainTable{
                zoom: 1;
                transform-origin: 0px 0px;
                width: 100%;
                background-color: transparent;
                border-spacing: 0;
                border-collapse: collapse;
                td{
                    // width: 50%;
                    text-align: center;
                    vertical-align: top;
                    padding: 0;
                }
                .node{
                    cursor: default;
                    margin: -5px 5px;
                    font-size: 12px!important;
                    color: #ca00c3;
                    display: inline-block;
                    width: max-content;
                    min-width: 15px;
                    margin: -5px 25px;
                    z-index: 10;
                    overflow: hidden;
                    word-break: break-all;
                    .profileImage{
                        max-width: 100%;
                        width: 74px;
                        height: 74px;
                        border: 2px solid #c8d5d8;
                        border-radius: 50%;
                        padding: 4px;
                        background: #fff;
                        cursor: pointer;
                    }
                }
            }
        }
    }
}
@media (max-width:990px) {
.MianContent .treeContent .treeChart .main .mainTable .node{min-width: 100px;}

}
@media (max-width:768px) {
    .searchForm{grid-template-columns: 1fr 160px !important}
 
}


@media (max-width:768px) {
    .Buttons{display: none;}
 
}

.tooltipContainerNew{

    width: 224px;
    height: auto;
    padding: 0;
    background: #fff;
    text-align: center;
    box-shadow: 0px 0px 20px #e5e5e5;
   

}





.tooltipContainerNew{

    width: 224px;
    height: auto;
    padding: 0;
    background: #fff;
    text-align: center;
    box-shadow: 0px 0px 20px #e5e5e5;
   

}





/********************** dark mode ********************/
.dark_mode_content{
    background-color: #03142B !important;
}

.dark_mode_tooltipList{
    background-color: #03142B !important;
}

.dark_mode_tooltipListItem{
    color:white !important;
}
/******************** end of dark mode ************/