//color
$color-white : #fff;

//page title
$page-title-bg-color : #f6f8f8;
$replica-icon-color : #ca00c3;


//dashboard variable
$dashboard-border-radius : 2px;
$tials-shadow-color : rgba(0, 0, 0, .05);
$panel-margin-bottom : 20px;
$panel-bg-color : #fff;
$panel-font-weight : 400;
$font-color : #6c787f;
$dashboard-color-blue :#ca00c3;
$dashboard-color-Lblue :#ca00c3;
$dahsboard-panel-width : 100%;

//panel design
$panel-shadow : 0 1px 10px rgba(0, 0, 0, .05);
$panel-padding-top-bottom: 15px;
//box
$dashboard-box-width : 110px;
$dashboard-box-height : 110px;
$dashboard-box-opacity : .6;
$dashboard-box-border-radius : 50%;

//box class color
$box-color-purple : rgba(154, 93, 231, .5);
$box-color-sky-blue : rgba(75, 225, 207, .5);;
$box-color-light : rgba(78, 165, 228, .5);;
$box-color-yellow :rgba(225, 181, 23, .5);

//font
$font-mulish: "Helvetica Neue", Helvetica, Arial, sans-serif, "Source Sans Pro";



//ewallet
$tiles-min-height : 96px;
$tiles-gap-height : 70px;

$button-padding : 5px 20px 6px 20px;

//tiles style
$tiles-grid-gap : 9px;
$tiles-margin-top : 3px;
$tiles-padding : 11px 12px;
$tiles-margin : 0 auto 0px auto;
//single tiles
$tile-single-padding : 22px 20px;
$tile-single-border-radius : 1px;
//single tile icon
$tile-single-left-icon-padding : 10px;
$tile-single-left-icon-marginRight : 10px;
$tile-single-left-icon-BorderRadius : 100%; 
$tile-single-left-icon-width-height : 55px;

//summary section
$summary-padding : 20px;
$summary-margin-bottom : 50px;
$table-content-box-shadow : 0px 1px 10px rgba(0, 0, 0, 0.15);
$table-content-padding : 25px;
$table-content-border-radius : 1px;

//tab content
$tab-content-padding-top : 15px;


//font color 
$font-color-black : '#000';

//input field height
$inputField-height : 34px;


//custom button variable
$button-padding : 4px 12px;
$button-font-size : 14px;
$button-margin-bottom : 0;

$color__back: #252224;
$color__back--high: lighten($color__back, 20%);
$color__text: #23527c;
$color__text--low: darken($color__text, 50%);
$color__primary: #23b7e5;
$color__white : #fff;

$checkbox__size: 20px;
$switch__size: $checkbox__size;

$checkbox__border__size: 1px;
$checkbox__border__color: $color__primary;
$checkbox__border__color--disabled: $color__back--high;

$checkbox__checkmark__size: $checkbox__size - (2*$checkbox__border__size);
$checkbox__checkmark__size--indeterminate: 2px;
$checkbox__checkmark__color: $color__primary;
$checkbox__checkmark__color--disabled: $color__back--high;

$checkbox__label__color: $color__text;
$checkbox__label__color--disabled: $color__text--low;
$checked_gap-size : 4px;


//primary color
$color-primary : #ff01f7;
//color success
$color-success : #4ead09;
//color info 
$color-info :#ff01f7;

//color black
$color-black  :#131e25;
//color gray
$color-gray:#f6f8f8;

//input typ height
$input-height : 34px;
