.notificaionHeader{    
  color: #58666e;
  background-color: #edf1f2;

}


.noDataHeader{
  border-color: #d4d6d6;
  color: #58666e;
  background-color: #edf1f2;
  border-radius: 2px 2px 0 0;
  padding: 15px 35px !important;
  border-bottom: 1px solid transparent;
}
.mailDropDown{
  width: 350px
}
.top_notification_sec .mailDropDown{
  width: 100%;
  // background-color: #d6d6d6;
}
.image{
  // margin-right: 15px;
  display: inline-block;
  width: 40px !important;
  img{
      width: 40px !important;
      height: 40px;
      max-width: 100%;
      vertical-align: middle;
      border-radius: 49%;
  }
  h1{
    width : 25px;
    height : 25px;
  }
}
.items{
  padding: 10px !important;
}
.viewBadge{
  position: absolute;
  right: 15px;
  padding: 3px 9px;
    font-weight: 300;
    padding-top: 5px;
}


//new design


@font-face {
  font-family: 'poppinslight';
  src: url('../../assets/fonts/poppins-light-webfont.woff2') format('woff2'),
       url('../../assets/fonts/poppins-light-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'poppinsmedium';
  src: url('../../assets/fonts/poppins-medium-webfont.woff2') format('woff2'),
       url('../../assets/fonts/poppins-medium-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'poppinsregular';
  src: url('../../assets/fonts/poppins-regular-webfont.woff2') format('woff2'),
       url('../../assets/fonts/poppins-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

} 

// body{margin: 0;padding: 0;background-image: url(../../assets/images/dashboard/main-background.jpg);background-size: 100% 100%;background-repeat:no-repeat ;    height: 100vh;  font-family: 'poppinsregular';}
body{margin: 0;padding: 0;background-image: url(../../assets/images/dashboard/main-background.jpg) !important;background-repeat:no-repeat ;    height: 100vh;  font-family: 'poppinsregular' !important;background-color: #f8fcff;}
.border-0{border: 0 !important;}
.main_contant_sec{
  width: 100%;
  height: auto;
  float: left;
    .topbar_section{
      position: fixed;
      z-index: 999;
      width: 100%;
      height: auto;
      float: left;
      padding: 10px 15px;
      background: #97298e;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.03);
      .logo_sec{
        width: 159px;
        height: auto;
        float: left;
        .logo_cn{
          width: 180%;
          height: auto;
          float: left;
          img{width: 100%;}
        }
        .dark_mode_menubartogglebtn{
          width: 30px;
          height: 30px;
          cursor: pointer;
          float: left;
          position: relative;
          top: 13px;
          margin-right: 10px;
          span{
            width: 25px;
            height: 2px;
            background-color: #ffffff;
            float: left;
            &::after{
              width: 50%;
              height: 2px;
              position: absolute;
              bottom: 10px;
              left: 0;
              background-color: #ffffff;
              content: '';
            }
            &::before{
              width: 50%;
              height: 2px;
              position: absolute;
              bottom: 19px;
              left: 0;
              background-color: #ffffff;
              content: '';
            }
          }
        }

      }


      .top_notification_user_sec{
        width: auto;
        float: right;
        height: 30px;
        margin-top: 12px;
        .top_notification_sec{
          width: 120px;
          height: auto;
          float: left;
          padding-right: 20px;
          margin-top: -10px;
          .top_notification_ico{
            width: 25px;
            height: 50px;
            float: left;
            color: rgba(0, 0, 0, 0.6);
            font-size: 15px;
            position: relative;
            margin-right: 25%;
            margin-top: 3px;
            img{width: 100%;}
            .notification_count{
              width: 20px;
              height: 20px;
              position: absolute;
              float: left;
              background-color: #f00;
              text-align: center;
              line-height: 20px;
              color: #fff;
              border-radius: 50px;
              right: -21px;
              top: 0px;
            }
            // &:hover .notification_msg_box{display: block;}
          }

          .notification_msg_box{
            width: 300px;
            max-height: 300px;
            position: absolute;
            right: 0;
            top: 45px;
            background-color: #fbfbfb;
            box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
            padding: 0px;
            border-radius: 5px;
            display: block;
            z-index: 9;
            overflow: auto;
            inset: 0px 0px auto auto !important;
            margin: 0px !important;
            transform: translate(37px, 51px) !important;
            .notification_msg_box_head{
              width: 100%;
              height: auto;
              float: left;
              padding-bottom: 10px;
              border-bottom: 1px #e5e5e5 solid;
              font-weight: bold;
              font-size: 15px;
              color: #242424;
            }
            .notification_msg_box_cnt{
              width: 100%;
              height: auto;
              float: left;
              max-height: 250px;
              overflow: auto;
              padding-top: 10px;
        
              .notification_msg_box_cnt_row{
                width: 100%;
                height: auto;
                float: left;
                margin-bottom: 10px;
                font-size: 14px;
                color: #646464;
              
                span{
                  width: 74%;
                  height: auto;
                  float: left;
                  font-size: 11px;
                  color: rgb(173, 173, 173);
                }
              }
            }
          }

          .tp_msg_btn.show{
            .notification_msg_box{display: block;}
          }
          .tp_not_btn.show{
            .notification_msg_box{display: block;}
          }
        
            .langButton{
              width: 20px;
              display: inline-block;
              &:hover{background-color: transparent !important;} 
            }
            .langContainer{
              text-align: center;
              display: flex;
             
            }
         }

        
        .top_user_acc_sec{
          width: auto;
          max-width: 220px;
          height: auto;
          float: left;
          color: #242424;
          font-size: 14px;
          line-height:30px;
          margin-top: -6px;
          button{border:0;background-color: transparent;}
          span{
            width: 30px;
            height: 30px;
            border-radius: 50%;
            float: left;
           // background-color: #777777;
            text-align: center;
            line-height: 30px;
            color: #fff;
            font-size: 18px;
            margin-right: 5px;
            overflow: hidden; 
            @media only screen and (max-width: 600px){
              margin-top:-1px;
            }
          }
          .dropdownmenu {
            right: 0;
            top: 47px;
            .dropdownitem{
              padding: 0rem 1rem;
            }
          }
        }
      }
.dropdowntoggle1.show .dropdown-menu {display: block;
  link{
    color: #000;
    text-decoration: none;
    
  }
}

    }
    .contant_sec{
      width: 100%;
      height: auto;
      float: left;
     
      .cntr_main_cnt_sc{
        width: 100%;
        height: auto;
        float: left;
        position: relative;
        padding-left: 210px;
     
          .left_navigation_sc{
            width: 200px;
            height: auto;
            float: left;
            position: absolute;
            left: 0;
            top: 0;
            z-index: 9;
            ul{
              padding: 0;
              padding-top: 20px;
              li{
                list-style: none;
  text-align: center;
  position: relative;
  margin-bottom: 15px;

              //   list-style: none;
              //   text-align: center;
              //  position: relative;
              //  margin-bottom: 15px;
              //  transition: 0.2s ease;
                a{
                  color: #666;
                  font-size: 15px;
                  text-decoration: none;
                  transition: 0.2s ease;
                  &:hover{color: #000;}
                  .materialiconsoutlined{
                    position: absolute;
                    left: 13px;
                    top: 9px;
                  }
                }
              
                strong{position: static;left: 70px;top:0px;display: block;background-color: transparent;    padding: 10px; color: #666;height: 40px;line-height: 20px;width: 100%;text-align: left;    margin-left: 36px;font-weight: normal;}
                &:hover strong{display: block;color: #000;}
                &:hover .submenu{display: block;padding: 10px ;opacity: 1;height: auto; pointer-events: inherit;}
                 .arrow_mn{position: absolute;right: 20px;font-size: 16px;top: 11px;}
                 .submenu{
                   padding: 0;
                  transition: opacity 1s ease-out;
                  opacity: 0;
                  height: 0;
                  overflow: hidden;
                  pointer-events: none;
                  //  position: absolute;
                  //  right: -90px;
                  //  top: 0;
                   background-color: transparent;
                   z-index: 9;
                    li{
                      margin-bottom: 5px;
                      text-align: left;
                      padding-left: 10px;
                      a{
                        text-decoration: none;
                        &:hover{color: #000;}
                      }
                    }
                 }
                
              }
              .active{
                a {
                  margin-bottom: 0px;
                  display: inline-block;
                  padding-top: 13px;
                  span{
                    width: 40px;
                    height: 40px;
                    background: #363636;
                    border-radius: 15px;
                    display: inline-block;
                    line-height: 40px;
                    color: #fff;
                    position: absolute;
                    &:after{
                      position: absolute;
                      width: 46px;
                      height: 26px;
                      left: -2px;
                      top: 26px;
                      content: '';
                      background: #363636;
                      /* opacity: 0.8; */
                      -webkit-filter: blur(12px);
                      filter: blur(12px);
                      border-radius: 15px;
                    }
                  }
                }
                
               
               
              }
            
            }
          }
         

          .dashboard_main_cnt{
            width: 100%;
            height: auto;
            float: left;
            padding: 20px;
            .dashboard_pannel_box{
              width: 100%;
              height: 100%;
              float: left;
              padding: 20px;
              background: #FFFFFF;
              box-shadow: 0px 9px 21px rgba(0, 0, 0, 0.03);
              border-radius: 10px;
              position: relative;
              margin-bottom: 15px;
              .profile_main_sec{
                width: 100%;
                height: auto;
                float: left;
                padding-left: 130px;
                border-right: 1px #e5e5e5 solid;
                position: relative;
                .profile_main_img_bx{
                  width: 100px;
                  height: 100px;
                  border-radius: 50%;
                  background-color: #fff;
                  border: 5px solid #FFFFFF;
                  box-sizing: border-box;
                  filter: drop-shadow(0px 4px 14px rgba(0, 0, 0, 0.15));
                  overflow: hidden;
                  position: absolute;
                  left: 10px;
                  top: 0px;
                  img{width: 100%;}
                }
                .profile_main_txt_sc{
                  width: 100%;
                  height: auto;
                  float: left;
                  
                  strong{
                    width: 100%;
                    height: auto;
                    float: left;
                    font-size: 22px;
                    color: #000;
                    margin-bottom: 5px;
                  }
                  span{
                    width: 100%;
                    height: auto;
                    float: left;
                    font-size: 14px;
                    color: #868686;
                    margin-bottom: 5px;
                  }
                  .view_prfl_btn{
                    width: 120px;
                    height: 30px;
                    display: inline-block;
                    filter: drop-shadow(0px 5px 14px rgba(86, 85, 88, 0.2));
                    border-radius: 60px;
                    border: 0.5px solid #bebebe;
                    text-align: center;
                    line-height: 28px;
                    font-size: 14px;
                    color: #6C6C6C;
                    text-decoration: none;
                    margin-top: 8px;
                    transition: 0.2s ease;
                    &:hover{background-color: #363636;color: #fff;}
                  }
                }
              }

              .profile_soc_share_sec{
                width: 100%;
                height: auto;
                float: left;
                border-right: 1px #e5e5e5 solid;
                position: relative;
                padding-top: 8px;
                padding-bottom: 15px;
                .profilesoc_share_bx{
                  width: 50%;
                  height: auto;
                  float: left;
                  margin-top: 10px;
                  .profilesoc_share_bx_hd{
                    width: 100%;
                    height: auto;
                    float: left;
                    font-size: 14px;
                    color: #5E5E5E;
                  }
                  .profilesoc_share_bx_row{
                    width: 100%;
                    height: auto;
                    float: left;
                    margin-top: 10px;
                    .profilesoc_share_bx_ico{
                      width: 35px;
                      height: 35px;
                      line-height: 30px;
                      margin-right: 2%;
                      text-align: center;
                      float: left;
                      background: #FFFFFF;
                      border-radius: 50%;
                      transition: 0.2s ease;
                      box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.17);
                      &:hover{box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.35);}
                      img{width: 15px;}

                    }
                  }
                }
              }
              .profile_other_box{
                width: 100%;
                height: auto;
                float: left;
                border-right: 1px #e5e5e5 solid;
                position: relative;
                .profile_other_box_cnt{
                  width: 100%;
                  height: auto;
                  float: left;
                  color: #868686;
                  font-size: 13px;
                  line-height: 20px;
                  margin-bottom: 10px;
                  strong{
                    width: 100%;
                    height: auto;
                    float: left;
                    color: #243040;
                    font-size: 16px;  
                  }
                }
              }



            } /**panel*/

           
            .dashboard_boxs_listing_sponcer_sec{
              width: 100%;
              height: auto;
              float: left;
              .lst_box_quick_select{
                width: 100%;
                height: auto;
                float: left;
                .lst_box_quick_select_btn{
                  position: absolute;
                  width: 4px;
                  font-size: 22px;
                  color: #242424;
                  right: 5px;
                  top: -3px;
                  line-height: 6px;
                  word-break: break-word;
                  padding: 6px 15px;
                  background-color: transparent;
                  border: 0;
                  z-index: 8;
                }
                
              }
              .show{
                .dropdown-menu {display: block !important;right: 10px;top: 33px;}
              }
              .boxes_lst_sec{
                grid-template-columns: repeat( auto-fit, minmax(25%, 1fr) );
                display: grid;
                height: 100%;
              .dashboard_pannel_box {padding: 0;}
              }
              .list_boxes_main{
                width: 100%;
                height: 100%;
                float: left;
              }
            
              
            .dashboard_pannel_box {box-shadow: 0px 9px 21px rgba(0, 0, 0, 0.07);height: 100%;
              border-radius: 10px;}
            .listing_box_sec{
              width: 100%;
              height: 100%;
              float: left;
              padding: 20px;
              overflow: hidden;
              position: relative;
              &::after{
                width: 150px;
                height: 150px;
                position: absolute;
                right: -70px;
                bottom: -70px;
                content: '';
                background: #FFE3E3;
                opacity: 0.6;
                border-radius: 120px;
              }
              .listing_box_ico_rnd{
                width: 80px;
                height: 70px;
                float: left;
                background: #DF3B3B;
                border-radius: 20px;
                margin-bottom: 20px;
                text-align: center;
                line-height: 60px;
                position: relative;
                &::after{
                  width: 50px;
                  height: 50px;
                  position: absolute;
                  left: 20px;
                  bottom: -23px;
                  content: '';
                  background: #E75D5D;
                  opacity: 0.6;
                  -webkit-filter: blur(24px);
                  filter: blur(19px);
                  border-radius: 5px;
                }
                img{width: 50%;}
              }
              .listing_box_head{
                width: 100%;
                height: auto;
                float: left;
                color: #535353;
                font-size: 17px;
                margin-top: 10px;
                line-height: 22px;
                position: relative;
                z-index: 1;
              }
              .listing_box_cnout{
                width: 100%;
                height: auto;
                float: left;
                font-weight: bold;
                font-size: 21px;
                font-family: 'poppinsmedium';
                color: #DF3B3B;
                position: relative;
                z-index: 1;
                // &::after{
                //   width: 3px;
                //   height: 20px;
                //   background-color: #DF3B3B;
                //   content: '';
                //   position: absolute;
                //   left: -20px;
                //   top: 6px
                // }
              }


            }

            .lst_box2{
              .listing_box_ico_rnd{background: #68CADF;&::after{background: #6ac8dd;}}
              .listing_box_sec{&::after{background: #D8F8FF;}}
              .listing_box_cnout{color: #68CADF;&::after{background-color: #68CADF;}}
            }
            .lst_box3{
              .listing_box_ico_rnd{background: #70A9FF;&::after{background: #8AB9FF;}}
              .listing_box_sec{&::after{background: #DFECFF;}}
              .listing_box_cnout{color: #70A9FF;&::after{background-color: #70A9FF;}}
            }
            .lst_box4{
              .listing_box_ico_rnd{background: #F273FD;&::after{background: #F273FD;}}
              .listing_box_sec{&::after{background: #FDE6FF;}}
              .listing_box_cnout{color: #F273FD;&::after{background-color: #F273FD;}}
            }


            .sponcer_sec{
              width: 100%;
              height: 100%;
              float: left;
              .sponcer_top_name_sec{
                width: 100%;
                height: auto;
                float: left;
                position: relative;
                padding-right: 50px;
                .sponcer_top_ico{
                  width: 50px;
                  height: auto;
                  position: absolute;
                  right: 10px;
                  top: 10px;
                }
                .sponcer_top_name_txt{
                  width: 100%;
                  height: auto;
                  float: left;
                  color: #0B5C97;
                  font-size: 22px;
                  margin-top: 5px;
                  span{
                    width: 100%;
                    height: auto;
                    float: left;
                    color: #868686;
                    font-size: 15px; 
                    margin-top: 3px;
                  }
                }
              }
              .spouncer_cnt_row{
                width: 100%;
                height: auto;
                float: left;
                margin-top: 40px;
                display: flex;
                flex-wrap: wrap;
                align-items: self-end;
                .spouncer_cnt_boxes{
                  width: 24%;
                  height: auto;
                  float: left;
                  margin-right: 1%;
                  color: #696969;
                  font-size: 13px;
                  margin-bottom: 5px;
                  strong{
                    width: 100%;
                    height: auto;
                    float: left;
                    color: #243040;
                    font-size: 18px;
                  }
                }
              }
            }


            }

            .dash_member_join_rank_sec{
              width: 100%;
              height: auto;
              float: left;
              margin-top: 20px;
              grid-template-columns: repeat( auto-fit, minmax(32%, 1fr) );
              gap: 1%;
              display: grid;
              .dashboard_new_member_sec{
                width: 100%;
                height: auto;
                float: left;
                .dashboard_new_member_head{
                  width: 100%;
                  height: auto;
                  float: left;
                  color: #2D2D2D;
                  font-size: 17px;
                  padding-bottom: 15px;
                }
                .dashboard_new_member_cnt_scrl{
                  width: 100%;
                  height: 300px;
                  overflow: auto;
                  float: left;
                
                }
                .dashboard_new_member_row{
                  width: 100%;
                  height: auto;
                  float: left;
                  margin-bottom: 8px;
                  border-bottom: 1px #e5e5e5 solid;
                  padding-bottom: 5px;
                  &:last-child {border-bottom: 0;}
                  .dashboard_new_member_sub_row{
                    width: 100%;
                    height: auto;
                    float: left;

                    .dashboard_new_member_name{
                      width: 70%;
                      height: auto;
                      float: left;
                      color: #6D617C;
                      font-size: 16px;
                      margin-bottom: 3px;
                     
                    }
                    .dashboard_new_member_amnt{
                      width: 30%;
                      height: auto;
                      float: right;
                      font-size: 16px;
                      color: #656565;
                      text-align: right;
                    }
                    .dashboard_new_member_id_date{
                      width: 70%;
                      height: auto;
                      float: left;
                      font-size: 13px;
                      color: #888888;
                    
                    }
                    .dashboard_new_member_date_sc{
                      width: 30%;
                      height: auto;
                      float: right;
                      font-size: 13px;
                      color: #888888;
                      text-align: right;
                    }
                  }
                 
                }
              }
              .dashboard_joining_sec{
                width: 100%;
                height: auto;
                float: left;
              .dashboard_newsec_head {
                width: 100%;
                height: auto;
                float: left;
                color: #2D2D2D;
                font-size: 17px;
                padding-bottom: 15px;
                }
                .dashboard_newsec_grph_sec{
                  width: 100%;
                  height: 300px;
                  float: left;
                }
              }
              .dashboard_rank_sec{
                width: 100%;
                height: auto;
                float: left;
                .dashboard_rank_scrl_sec{
                  width: 100%;
                  height: 350px;
                  overflow: auto;
                  float: left;
                  .dashboard_rank_row{
                    width: 100%;
                    height: auto;
                    float: left;
                    margin-bottom: 5px;
                    padding-bottom: 10px;
                    border-bottom: 1px #e5e5e5 solid;
                    .dashboard_rank_row_head{
                      width: 100%;
                      height: auto;
                      float: left;
                      font-size: 14px;
                      margin-bottom: -10px;
                      span{color: #2855BF;}
                    }
                    .dashboard_rank{
                      width: 70%;
                      height: auto;
                      float: left;
                      position: relative;
                      padding-left: 40px;
                      margin-top: 18px;
                      .dashboard_rank_ico{
                        width: 35px;
                        height: 35px;
                        border-radius: 10px;
                        background: linear-gradient(180deg, #6882DF 0%, #2D70BE 100%);
                        text-align: center;
                        line-height: 30px;
                        position: absolute;
                        left: 0;
                        top: 2px;
                      }
                      .dashboard_rank_hd{
                        width: 100%;
                        height: auto;
                        float: left;
                        font-size: 13px; 
                        color: #868686;
                        margin-bottom: 5px;
                      }
                      .dashboard_rank_prg_bar{
                        width: 100%;
                        height: auto;
                        float: left;
                        font-size: 13px; 
                        color: #868686;
                        padding-right: 30px;
                        position: relative;
                        .dashboard_rank_prg_bar_cnt{
                          width: 25px;
                          height: auto;
                          position: absolute;
                          right: 2px;
                          color: #444444;
                          font-size: 13px;
                          top: -6px;
                        }
                        .progress {
                          width: 100%;
                          height: 5px;
                        }
                        .progress-wrap {
                          background: #ddd;
                          margin: 0px 0;
                          overflow: hidden;
                          position: relative;
                          .progress-bar {
                            background: #004073;
                            left: 0;
                            position: absolute;
                            top: 0;
                          }
                        }
                        
                      }
                    }
                    .dashboard_rank_cnt_sc{
                      width: 30%;
                      height: auto;
                      float: left;
                      .dashboard_rank_box{
                        width: 50%;
                        height: auto;
                        float: left;
                        text-align: center;
                        &:nth-child(2) .dashboard_rank_box_rnd{background-color: #F2F2D8;}
                        .dashboard_rank_box_rnd{
                          width: 35px;
                          height: 35px;
                          background-color: #D9EFF4;
                          text-align: center;
                          line-height: 36px;
                          color: #242424;
                          font-size: 14px;
                          display: inline-block;
                          border-radius: 50%;
                         
                        }
                        span{
                          width: 100%;
                          height: auto;
                          float: left;
                          font-size: 13px; 
                          white-space: nowrap;
                          color: #4B4A4A;  
                        }
                      }
                    }

                  }
                }
              }
            }

            .dashbord_earning_expence{
              width: 100%;
              height: auto;
              float: left;
              margin-top: 15px;
              grid-template-columns: repeat( auto-fit, minmax(49%, 1fr) );
              gap: 1%;
              display: grid;
             
              .dashbrd_earing_exp_sec{
                width: 100%;
                height: auto;
                float: left;
              }
              .dashbord_earning_expence_cnt_sc{
                width: 100%;
                height: auto;
                float: left;
                margin-top: 10px;
                .earning-tabs-row{
                  width: 100%;
                  height: auto;
                  float: left;
                  margin-bottom: 10px;
                  padding-bottom: 8px;
                  border-bottom: 1px #e5e5e5 solid;
                  &:last-child{border: 0;}
                  .earning-tabs-name{
                    width: 70%;
                    height: auto;
                    float: left;
                    color: #565656;
                    font-size: 15px;
                    span{
                      color: #03AD47;
                      font-size: 14px;
                      float: right;
                    }
                  }
                  .earning_right_ico{
                    width: 30%;
                    float: right;
                    height: auto;
                    text-align: center;
                    span{
                      width: 30px;
                      height: 30px;
                      float: right;
                      background: #70A9FF;
                      border-radius: 50%;
                      text-align: center;
                      line-height: 30px;
                      color: #fff;
                      font-size: 13px;
                      margin-top: -5px;
                    }
                  }
                }
              }

              .dash_perfomencers{
                  width: 100%;
                  height: auto;
                  float: left;
                  .dash_perfomencers_row {
                    width: 100%;
                    height: auto;
                    float: left;
                    margin-bottom: 8px;
                    border-bottom: 1px #e5e5e5 solid;
                    padding-bottom: 5px;
                    position: relative;
                    padding-left: 45px;
                    .dash_perfomencers_prf_ico{
                      width: 30px;
                      height: 30px;
                      position: absolute;
                      left: 0;
                      top: 0;
                      background-color: #ccc;
                      overflow: hidden;
                      border-radius: 50%;
                    }
                      .dash_perfomencers_name {
                        width: 70%;
                        height: auto;
                        float: left;
                        color: #6D617C;
                        font-size: 16px;
                        margin-bottom: 3px;
                        span{
                          width: 100%;
                        height: auto;
                        float: left;
                        color: #a1a1a1;
                        font-size: 13px;
                        }
                    }
                    .dash_perfomencers_amnt {
                      width: 30%;
                      height: auto;
                      float: right;
                      font-size: 16px;
                      color: #656565;
                      text-align: right; 
                  }
                }
              }
             
            }

          }/**main_cnt*/
         
      }
    }
}

.left_navigation_fold {
  .cntr_main_cnt_sc{   padding-left: 100px !important; }
  .left_navigation_sc{
    width: 80px !important;
    
  .submenu{
    width: 150px  !important;
    height: auto !important;
    position: absolute !important;
    left:60px !important;
    top:30px !important;
    background-color: #fff !important;
    // display: none !important;
    transition: opacity 0s ease-out !important;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.3);
    li{margin-bottom: 5px !important;}
  }
  li{
    margin-bottom: 30px !important;
    strong{display: none !important;}
    a{
      .material-icons-outlined{position: static !important;}
    }
   &:hover strong{
    display: block !important;
    position: absolute !important;
    left: 24px !important;
    width: 150px !important;
    padding-left: 20px !important;
    background-color: #fff !important;
    box-shadow: 0px -12px 15px rgba(0, 0, 0, 0.2); 
    z-index: 10 !important;
   }
  }
  .arrow_mn{display: none !important;}
  
  .active {
    a {margin-bottom: 0 !important;
      
      strong{
        left:35px !important;
        top: 15px !important;
      }
     span{
        width: 60px !important;
        height: 50px !important;
        line-height: 50px !important;
      &:after{
        left: 14px !important;
        top: 45px !important;
      }
     }
    }
  }  
  }
  
}
.lst_box_quick_select{
  width: 100%;
  height: auto;
  float: left;
  .lst_box_quick_select_btn{
    position: absolute;
    width: 4px;
    font-size: 22px;
    color: #242424;
    right: 5px;
    top: -3px;
    line-height: 6px;
    word-break: break-word;
    padding: 6px 15px;
    background-color: transparent;
    border: 0;
    z-index: 8;
  }
  
}
.show{
  .dropdown-menu {display: block !important;right: 10px;top: 33px;}
}
.dropdown-item{font-size: 14px;}

.pagetTileText{
  margin-top:30px;
}

.arrow_main_pg{
  margin-top: -9px;
  @media only screen and (max-width:991px){
    margin-top: 0px;
  }
}
 
@media (max-width:1500px) {
  .main_contant_sec  .dashboard_rank_cnt_sc { width: 40% !important;}
  .main_contant_sec .dashboard_rank {width: 60% !important;}
}
@media (max-width:1386px) {
  .resp-tabs-list li{padding: 5px 7px !important;}
  .listing_box_cnout{    font-size: 19px !important;}
  .left_navigation_fold .cntr_main_cnt_sc { padding-left: 70px !important;}
  .listing_box_sec{padding: 10px !important;}
  .dashboard_rank_box span{font-size: 12px !important;}
  .listing_box_ico_rnd{    margin-top: 20px !important; }
} 
@media (max-width:1265px) {
  .profilesoc_share_bx_ico {width: 25px !important;height: 25px !important;line-height: 21px !important;}
  .mid_flll_wdth{width: 100% !important;}
  .list_boxes_main{margin-bottom: 15px;height: auto !important;}
  .dash_member_join_rank_sec{grid-template-columns: repeat(auto-fit, minmax(100%, 1fr)) !important; gap: 0 !important}
  .dashbord_earning_expence{grid-template-columns: repeat(auto-fit, minmax(100%, 1fr)) !important; gap: 0 !important}
  .dashboard_pannel_box{height: auto !important;}
  .dashbord_earning_expence{margin-top: 0 !important;}
  .dash_member_join_rank_sec{margin-top: 0 !important;}
  .list_boxes_main{margin-bottom: 0 !important;}
}
@media (max-width:991px) {
  .profile_other_box{border: 0 !important;}
  .profile_main_sec{border: 0 !important;}
  .profile_soc_share_sec{border: 0 !important;}
}
@media (max-width:768px) {
  .main_contant_sec  .boxes_lst_sec { grid-template-columns: repeat(auto-fit, minmax(100%, 1fr)) !important;}
  .listing_box_sec{text-align: center;  }
  .listing_box_ico_rnd{display: inline-block;float: none !important;}
  // .menubartogglebtn{display: none;}
  .notification_msg_box{z-index: 99 !important;right: -90px !important; }
  .top_user_acc_sec button{font-size: 0;}
.top_user_acc_sec button span{font-size: 15px;}
.logo_sec{width: 150px !important;}
}
@media (max-width:500px) {
  .notification_msg_box{width: 260px !important;transform: translate(67px, 51px) !important;}
.top_notification_sec {width: 90px !important;padding-right: 0; }
.top_notification_ico{   margin-right: 13% !important;}
.profile_main_sec{padding-left: 0px !important; padding-top: 80px;}
.profile_main_img_bx {  width: 70px !important;;height: 70px !important;;left: 0px !important;;}
.topbar_section .logo_sec { width: 140px !important;}
.top_notification_user_sec{position: absolute;right: 0;}
.profilesoc_share_bx {  width: 100% !important;}
.dashboard_main_cnt{padding: 10px !important;}
.dashboard_pannel_box{padding: 10px !important;}
.dashboard_new_member_id_date{width: 100% !important;}
.dashboard_new_member_name{width: 100% !important;}
.dashboard_new_member_date_sc{width: 100% !important;text-align: left !important;}
.dashboard_new_member_amnt{width: 100% !important;text-align: left !important;}
.dashboard_new_member_name{font-weight: 600 !important;}
.spouncer_cnt_boxes {  width: 49% !important;}
.earning-tabs-name span{float: left !important;width: 100%;}
.main_contant_sec .dashboard_rank { width: 97% !important;}
.main_contant_sec .dashboard_rank_cnt_sc { width: 100% !important;  margin-top: 14px !important;}
h2.resp-accordion{border: 0 !important; background-color: transparent !important;}
.dash_perfomencers_name { width:100% !important;}
.dash_perfomencers_amnt {width: 100% !important;text-align: left !important;}
.dash_perfomencers_name span{    font-size: 13px}
h2.resp-accordion{padding: 12px 5px;}
.logo_cn{width: 100px !important;margin-top: 10px;}

}


/************************** dark mode ****************************/

body{margin: 0;padding: 0;background-image: url(../../assets/images/dashboard/main-background.jpg) !important;background-repeat:no-repeat ;    height: 100vh;  font-family: 'poppinsregular' !important;background-color: #f8fcff;}
.border-0{border: 0 !important;}
.main_contant_sec{
  width: 100%;
  height: auto;
  float: left;
    .topbar_section{
      position: fixed;
      z-index: 999;
      width: 100%;
      height: auto;
      float: left;
      padding: 10px 15px;
      background: #FFFFFF;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.03);
      .logo_sec{
        width: 200px;
        height: auto;
        float: left;
        .logo_cn{
          width: 130px;
          height: auto;
          float: left;
          img{width: 138%;
          max-height : 50px}
        }
        .menubartogglebtn{
          width: 30px;
          height: 30px;
          cursor: pointer;
          float: left;
          position: relative;
          top: 13px;
          margin-right: 10px;
          span{
            width: 25px;
            height: 2px;
            background-color: #000000;
            float: left;
            &::after{
              width: 50%;
              height: 2px;
              position: absolute;
              bottom: 10px;
              left: 0;
              background-color: #000000;
              content: '';
            }
            &::before{
              width: 50%;
              height: 2px;
              position: absolute;
              bottom: 19px;
              left: 0;
              background-color: #000000;
              content: '';
            }
          }
        }

      }


      .top_notification_user_sec{
        width: auto;
        float: right;
        height: 30px;
        margin-top: 12px;
        .top_notification_sec{
          width: 120px;
          height: auto;
          float: left;
          padding-right: 20px;
          margin-top: -10px;
          .top_notification_ico{
            width: 25px;
            height: 50px;
            float: left;
            color: rgba(0, 0, 0, 0.6);
            font-size: 15px;
            position: relative;
            margin-right: 25%;
            margin-top: 3px;
            img{width: 100%;}
            .notification_count{
              width: 20px;
              height: 20px;
              position: absolute;
              float: left;
              background-color: #f00;
              text-align: center;
              line-height: 20px;
              color: #fff;
              border-radius: 50px;
              right: -21px;
              top: 0px;
            }
            // &:hover .notification_msg_box{display: block;}
          }

          .notification_msg_box{
            width: 300px;
            max-height: 300px;
            position: absolute;
            right: 0;
            top: 45px;
            background-color: #fbfbfb;
            box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
            padding: 0px;
            border-radius: 5px;
            display: block;
            z-index: 9;
            overflow: auto;
            inset: 0px 0px auto auto !important;
            margin: 0px !important;
            transform: translate(37px, 51px) !important;
            .notification_msg_box_head{
              width: 100%;
              height: auto;
              float: left;
              padding-bottom: 10px;
              border-bottom: 1px #e5e5e5 solid;
              font-weight: bold;
              font-size: 15px;
              color: #242424;
            }
            .notification_msg_box_cnt{
              width: 100%;
              height: auto;
              float: left;
              max-height: 250px;
              overflow: auto;
              padding-top: 10px;
        
              .notification_msg_box_cnt_row{
                width: 100%;
                height: auto;
                float: left;
                margin-bottom: 10px;
                font-size: 14px;
                color: #646464;
              
                span{
                  width: 74%;
                  height: auto;
                  float: left;
                  font-size: 11px;
                  color: rgb(173, 173, 173);
                }
              }
            }
          }

          .tp_msg_btn.show{
            .notification_msg_box{display: block;}
          }
          .tp_not_btn.show{
            .notification_msg_box{display: block;}
          }
        
            .langButton{
              width: 20px;
              display: inline-block;
              &:hover{background-color: transparent !important;} 
            }
            .langContainer{
              text-align: center;
              display: flex;
             
            }
         }

        
        .top_user_acc_sec{
          width: auto;
          max-width: 220px;
          height: auto;
          float: left;
          color: #242424;
          font-size: 14px;
          line-height:30px;
          margin-top: -6px;
          button{border:0;background-color: transparent;}
          span{
            width: 30px;
            height: 30px;
            border-radius: 50%;
            float: left;
           // background-color: #777777;
            text-align: center;
            line-height: 30px;
            color: #fff;
            font-size: 18px;
            margin-right: 5px;
            overflow: hidden; 
            @media only screen and (max-width: 600px){
              margin-top:-1px;
            }
          }
          .dropdownmenu {
            right: 0;
            top: 47px;
            .dropdownitem{
              padding: 0rem 1rem;
            }
          }
        }
      }
.dropdowntoggle1.show .dropdown-menu {display: block;
  link{
    color: #000;
    text-decoration: none;
    
  }
}

    }
    .contant_sec{
      width: 100%;
      height: auto;
      float: left;
     
      .cntr_main_cnt_sc{
        width: 100%;
        height: auto;
        float: left;
        position: relative;
        padding-left: 210px;
     
          .left_navigation_sc{
            width: 200px;
            height: auto;
            float: left;
            position: absolute;
            left: 0;
            top: 0;
            z-index: 9;
            ul{
              padding: 0;
              padding-top: 20px;
              li{
                list-style: none;
  text-align: center;
  position: relative;
  margin-bottom: 15px;

              //   list-style: none;
              //   text-align: center;
              //  position: relative;
              //  margin-bottom: 15px;
              //  transition: 0.2s ease;
                a{
                  color: #666;
                  font-size: 15px;
                  text-decoration: none;
                  transition: 0.2s ease;
                  &:hover{color: #000;}
                  .materialiconsoutlined{
                    position: absolute;
                    left: 13px;
                    top: 9px;
                  }
                }
              
                strong{position: static;left: 70px;top:0px;display: block;background-color: transparent;    padding: 10px; color: #666;height: 40px;line-height: 20px;width: 100%;text-align: left;    margin-left: 36px;font-weight: normal;}
                &:hover strong{display: block;color: #000;}
                &:hover .submenu{display: block;padding: 10px ;opacity: 1;height: auto; pointer-events: inherit;}
                 .arrow_mn{position: absolute;right: 20px;font-size: 16px;top: 11px;}
                 .submenu{
                   padding: 0;
                  transition: opacity 1s ease-out;
                  opacity: 0;
                  height: 0;
                  overflow: hidden;
                  pointer-events: none;
                  //  position: absolute;
                  //  right: -90px;
                  //  top: 0;
                   background-color: transparent;
                   z-index: 9;
                    li{
                      margin-bottom: 5px;
                      text-align: left;
                      padding-left: 10px;
                      a{
                        text-decoration: none;
                        &:hover{color: #000;}
                      }
                    }
                 }
                
              }
              .active{
                a {
                  margin-bottom: 0px;
                  display: inline-block;
                  padding-top: 13px;
                  span{
                    width: 40px;
                    height: 40px;
                    background: #363636;
                    border-radius: 15px;
                    display: inline-block;
                    line-height: 40px;
                    color: #fff;
                    position: absolute;
                    &:after{
                      position: absolute;
                      width: 46px;
                      height: 26px;
                      left: -2px;
                      top: 26px;
                      content: '';
                      background: #363636;
                      /* opacity: 0.8; */
                      -webkit-filter: blur(12px);
                      filter: blur(12px);
                      border-radius: 15px;
                    }
                  }
                }
                
               
               
              }
            
            }
          }
         

          .dashboard_main_cnt{
            width: 100%;
            height: auto;
            float: left;
            padding: 20px;
            .dashboard_pannel_box{
              width: 100%;
              height: 100%;
              float: left;
              padding: 20px;
              background: #FFFFFF;
              box-shadow: 0px 9px 21px rgba(0, 0, 0, 0.03);
              border-radius: 10px;
              position: relative;
              margin-bottom: 15px;
              .profile_main_sec{
                width: 100%;
                height: auto;
                float: left;
                padding-left: 130px;
                border-right: 1px #e5e5e5 solid;
                position: relative;
                .profile_main_img_bx{
                  width: 100px;
                  height: 100px;
                  border-radius: 50%;
                  background-color: #fff;
                  border: 5px solid #FFFFFF;
                  box-sizing: border-box;
                  filter: drop-shadow(0px 4px 14px rgba(0, 0, 0, 0.15));
                  overflow: hidden;
                  position: absolute;
                  left: 10px;
                  top: 0px;
                  img{width: 100%;}
                }
                .profile_main_txt_sc{
                  width: 100%;
                  height: auto;
                  float: left;
                  
                  strong{
                    width: 100%;
                    height: auto;
                    float: left;
                    font-size: 22px;
                    color: #000;
                    margin-bottom: 5px;
                  }
                  span{
                    width: 100%;
                    height: auto;
                    float: left;
                    font-size: 14px;
                    color: #868686;
                    margin-bottom: 5px;
                  }
                  .view_prfl_btn{
                    width: 120px;
                    height: 30px;
                    display: inline-block;
                    filter: drop-shadow(0px 5px 14px rgba(86, 85, 88, 0.2));
                    border-radius: 60px;
                    border: 0.5px solid #bebebe;
                    text-align: center;
                    line-height: 28px;
                    font-size: 14px;
                    color: #6C6C6C;
                    text-decoration: none;
                    margin-top: 8px;
                    transition: 0.2s ease;
                    &:hover{background-color: #363636;color: #fff;}
                  }
                }
              }

              .profile_soc_share_sec{
                width: 100%;
                height: auto;
                float: left;
                border-right: 1px #e5e5e5 solid;
                position: relative;
                padding-top: 8px;
                padding-bottom: 15px;
                .profilesoc_share_bx{
                  width: 50%;
                  height: auto;
                  float: left;
                  margin-top: 10px;
                  .profilesoc_share_bx_hd{
                    width: 100%;
                    height: auto;
                    float: left;
                    font-size: 14px;
                    color: #5E5E5E;
                  }
                  .profilesoc_share_bx_row{
                    width: 100%;
                    height: auto;
                    float: left;
                    margin-top: 10px;
                    .profilesoc_share_bx_ico{
                      width: 35px;
                      height: 35px;
                      line-height: 30px;
                      margin-right: 2%;
                      text-align: center;
                      float: left;
                      background: #FFFFFF;
                      border-radius: 50%;
                      transition: 0.2s ease;
                      box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.17);
                      &:hover{box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.35);}
                      img{width: 15px;}

                    }
                  }
                }
              }
              .profile_other_box{
                width: 100%;
                height: auto;
                float: left;
                border-right: 1px #e5e5e5 solid;
                position: relative;
                .profile_other_box_cnt{
                  width: 100%;
                  height: auto;
                  float: left;
                  color: #868686;
                  font-size: 13px;
                  line-height: 20px;
                  margin-bottom: 10px;
                  strong{
                    width: 100%;
                    height: auto;
                    float: left;
                    color: #243040;
                    font-size: 16px;  
                  }
                }
              }



            } /**panel*/

           
            .dashboard_boxs_listing_sponcer_sec{
              width: 100%;
              height: auto;
              float: left;
              .lst_box_quick_select{
                width: 100%;
                height: auto;
                float: left;
                .lst_box_quick_select_btn{
                  position: absolute;
                  width: 4px;
                  font-size: 22px;
                  color: #242424;
                  right: 5px;
                  top: -3px;
                  line-height: 6px;
                  word-break: break-word;
                  padding: 6px 15px;
                  background-color: transparent;
                  border: 0;
                  z-index: 8;
                }
                
              }
              .show{
                .dropdown-menu {display: block !important;right: 10px;top: 33px;}
              }
              .boxes_lst_sec{
                grid-template-columns: repeat( auto-fit, minmax(25%, 1fr) );
                display: grid;
                height: 100%;
              .dashboard_pannel_box {padding: 0;}
              }
              .list_boxes_main{
                width: 100%;
                height: 100%;
                float: left;
              }
            
              
            .dashboard_pannel_box {box-shadow: 0px 9px 21px rgba(0, 0, 0, 0.07);height: 100%;
              border-radius: 10px;}
            .listing_box_sec{
              width: 100%;
              height: 100%;
              float: left;
              padding: 20px;
              overflow: hidden;
              position: relative;
              &::after{
                width: 150px;
                height: 150px;
                position: absolute;
                right: -70px;
                bottom: -70px;
                content: '';
                background: #FFE3E3;
                opacity: 0.6;
                border-radius: 120px;
              }
              .listing_box_ico_rnd{
                width: 80px;
                height: 70px;
                float: left;
                background: #DF3B3B;
                border-radius: 20px;
                margin-bottom: 20px;
                text-align: center;
                line-height: 60px;
                position: relative;
                &::after{
                  width: 50px;
                  height: 50px;
                  position: absolute;
                  left: 20px;
                  bottom: -23px;
                  content: '';
                  background: #E75D5D;
                  opacity: 0.6;
                  -webkit-filter: blur(24px);
                  filter: blur(19px);
                  border-radius: 5px;
                }
                img{width: 50%;}
              }
              .listing_box_head{
                width: 100%;
                height: auto;
                float: left;
                color: #535353;
                font-size: 17px;
                margin-top: 10px;
                line-height: 22px;
                position: relative;
                z-index: 1;
              }
              .listing_box_cnout{
                width: 100%;
                height: auto;
                float: left;
                font-weight: bold;
                font-size: 21px;
                font-family: 'poppinsmedium';
                color: #DF3B3B;
                position: relative;
                z-index: 1;
                // &::after{
                //   width: 3px;
                //   height: 20px;
                //   background-color: #DF3B3B;
                //   content: '';
                //   position: absolute;
                //   left: -20px;
                //   top: 6px
                // }
              }


            }

            .lst_box2{
              .listing_box_ico_rnd{background: #68CADF;&::after{background: #6ac8dd;}}
              .listing_box_sec{&::after{background: #D8F8FF;}}
              .listing_box_cnout{color: #68CADF;&::after{background-color: #68CADF;}}
            }
            .lst_box3{
              .listing_box_ico_rnd{background: #70A9FF;&::after{background: #8AB9FF;}}
              .listing_box_sec{&::after{background: #DFECFF;}}
              .listing_box_cnout{color: #70A9FF;&::after{background-color: #70A9FF;}}
            }
            .lst_box4{
              .listing_box_ico_rnd{background: #F273FD;&::after{background: #F273FD;}}
              .listing_box_sec{&::after{background: #FDE6FF;}}
              .listing_box_cnout{color: #F273FD;&::after{background-color: #F273FD;}}
            }


            .sponcer_sec{
              width: 100%;
              height: 100%;
              float: left;
              .sponcer_top_name_sec{
                width: 100%;
                height: auto;
                float: left;
                position: relative;
                padding-right: 50px;
                .sponcer_top_ico{
                  width: 50px;
                  height: auto;
                  position: absolute;
                  right: 10px;
                  top: 10px;
                }
                .sponcer_top_name_txt{
                  width: 100%;
                  height: auto;
                  float: left;
                  color: #0B5C97;
                  font-size: 22px;
                  margin-top: 5px;
                  span{
                    width: 100%;
                    height: auto;
                    float: left;
                    color: #868686;
                    font-size: 15px; 
                    margin-top: 3px;
                  }
                }
              }
              .spouncer_cnt_row{
                width: 100%;
                height: auto;
                float: left;
                margin-top: 40px;
                display: flex;
                flex-wrap: wrap;
                align-items: self-end;
                .spouncer_cnt_boxes{
                  width: 24%;
                  height: auto;
                  float: left;
                  margin-right: 1%;
                  color: #696969;
                  font-size: 13px;
                  margin-bottom: 5px;
                  strong{
                    width: 100%;
                    height: auto;
                    float: left;
                    color: #243040;
                    font-size: 18px;
                  }
                }
              }
            }


            }

            .dash_member_join_rank_sec{
              width: 100%;
              height: auto;
              float: left;
              margin-top: 20px;
              grid-template-columns: repeat( auto-fit, minmax(32%, 1fr) );
              gap: 1%;
              display: grid;
              .dashboard_new_member_sec{
                width: 100%;
                height: auto;
                float: left;
                .dashboard_new_member_head{
                  width: 100%;
                  height: auto;
                  float: left;
                  color: #2D2D2D;
                  font-size: 17px;
                  padding-bottom: 15px;
                }
                .dashboard_new_member_cnt_scrl{
                  width: 100%;
                  height: 300px;
                  overflow: auto;
                  float: left;
                
                }
                .dashboard_new_member_row{
                  width: 100%;
                  height: auto;
                  float: left;
                  margin-bottom: 8px;
                  border-bottom: 1px #e5e5e5 solid;
                  padding-bottom: 5px;
                  &:last-child {border-bottom: 0;}
                  .dashboard_new_member_sub_row{
                    width: 100%;
                    height: auto;
                    float: left;

                    .dashboard_new_member_name{
                      width: 70%;
                      height: auto;
                      float: left;
                      color: #6D617C;
                      font-size: 16px;
                      margin-bottom: 3px;
                     
                    }
                    .dashboard_new_member_amnt{
                      width: 30%;
                      height: auto;
                      float: right;
                      font-size: 16px;
                      color: #656565;
                      text-align: right;
                    }
                    .dashboard_new_member_id_date{
                      width: 70%;
                      height: auto;
                      float: left;
                      font-size: 13px;
                      color: #888888;
                    
                    }
                    .dashboard_new_member_date_sc{
                      width: 30%;
                      height: auto;
                      float: right;
                      font-size: 13px;
                      color: #888888;
                      text-align: right;
                    }
                  }
                 
                }
              }
              .dashboard_joining_sec{
                width: 100%;
                height: auto;
                float: left;
              .dashboard_newsec_head {
                width: 100%;
                height: auto;
                float: left;
                color: #2D2D2D;
                font-size: 17px;
                padding-bottom: 15px;
                }
                .dashboard_newsec_grph_sec{
                  width: 100%;
                  height: 300px;
                  float: left;
                }
              }
              .dashboard_rank_sec{
                width: 100%;
                height: auto;
                float: left;
                .dashboard_rank_scrl_sec{
                  width: 100%;
                  height: 350px;
                  overflow: auto;
                  float: left;
                  .dashboard_rank_row{
                    width: 100%;
                    height: auto;
                    float: left;
                    margin-bottom: 5px;
                    padding-bottom: 10px;
                    border-bottom: 1px #e5e5e5 solid;
                    .dashboard_rank_row_head{
                      width: 100%;
                      height: auto;
                      float: left;
                      font-size: 14px;
                      margin-bottom: -10px;
                      span{color: #2855BF;}
                    }
                    .dashboard_rank{
                      width: 70%;
                      height: auto;
                      float: left;
                      position: relative;
                      padding-left: 40px;
                      margin-top: 18px;
                      .dashboard_rank_ico{
                        width: 35px;
                        height: 35px;
                        border-radius: 10px;
                        background: linear-gradient(180deg, #6882DF 0%, #2D70BE 100%);
                        text-align: center;
                        line-height: 30px;
                        position: absolute;
                        left: 0;
                        top: 2px;
                      }
                      .dashboard_rank_hd{
                        width: 100%;
                        height: auto;
                        float: left;
                        font-size: 13px; 
                        color: #868686;
                        margin-bottom: 5px;
                      }
                      .dashboard_rank_prg_bar{
                        width: 100%;
                        height: auto;
                        float: left;
                        font-size: 13px; 
                        color: #868686;
                        padding-right: 30px;
                        position: relative;
                        .dashboard_rank_prg_bar_cnt{
                          width: 25px;
                          height: auto;
                          position: absolute;
                          right: 2px;
                          color: #444444;
                          font-size: 13px;
                          top: -6px;
                        }
                        .progress {
                          width: 100%;
                          height: 5px;
                        }
                        .progress-wrap {
                          background: #ddd;
                          margin: 0px 0;
                          overflow: hidden;
                          position: relative;
                          .progress-bar {
                            background: #004073;
                            left: 0;
                            position: absolute;
                            top: 0;
                          }
                        }
                        
                      }
                    }
                    .dashboard_rank_cnt_sc{
                      width: 30%;
                      height: auto;
                      float: left;
                      .dashboard_rank_box{
                        width: 50%;
                        height: auto;
                        float: left;
                        text-align: center;
                        &:nth-child(2) .dashboard_rank_box_rnd{background-color: #F2F2D8;}
                        .dashboard_rank_box_rnd{
                          width: 35px;
                          height: 35px;
                          background-color: #D9EFF4;
                          text-align: center;
                          line-height: 36px;
                          color: #242424;
                          font-size: 14px;
                          display: inline-block;
                          border-radius: 50%;
                         
                        }
                        span{
                          width: 100%;
                          height: auto;
                          float: left;
                          font-size: 13px; 
                          white-space: nowrap;
                          color: #4B4A4A;  
                        }
                      }
                    }

                  }
                }
              }
            }

            .dashbord_earning_expence{
              width: 100%;
              height: auto;
              float: left;
              margin-top: 15px;
              grid-template-columns: repeat( auto-fit, minmax(49%, 1fr) );
              gap: 1%;
              display: grid;
             
              .dashbrd_earing_exp_sec{
                width: 100%;
                height: auto;
                float: left;
              }
              .dashbord_earning_expence_cnt_sc{
                width: 100%;
                height: auto;
                float: left;
                margin-top: 10px;
                .earning-tabs-row{
                  width: 100%;
                  height: auto;
                  float: left;
                  margin-bottom: 10px;
                  padding-bottom: 8px;
                  border-bottom: 1px #e5e5e5 solid;
                  &:last-child{border: 0;}
                  .earning-tabs-name{
                    width: 70%;
                    height: auto;
                    float: left;
                    color: #565656;
                    font-size: 15px;
                    span{
                      color: #03AD47;
                      font-size: 14px;
                      float: right;
                    }
                  }
                  .earning_right_ico{
                    width: 30%;
                    float: right;
                    height: auto;
                    text-align: center;
                    span{
                      width: 30px;
                      height: 30px;
                      float: right;
                      background: #70A9FF;
                      border-radius: 50%;
                      text-align: center;
                      line-height: 30px;
                      color: #fff;
                      font-size: 13px;
                      margin-top: -5px;
                    }
                  }
                }
              }

              .dash_perfomencers{
                  width: 100%;
                  height: auto;
                  float: left;
                  .dash_perfomencers_row {
                    width: 100%;
                    height: auto;
                    float: left;
                    margin-bottom: 8px;
                    border-bottom: 1px #e5e5e5 solid;
                    padding-bottom: 5px;
                    position: relative;
                    padding-left: 45px;
                    .dash_perfomencers_prf_ico{
                      width: 30px;
                      height: 30px;
                      position: absolute;
                      left: 0;
                      top: 0;
                      background-color: #ccc;
                      overflow: hidden;
                      border-radius: 50%;
                    }
                      .dash_perfomencers_name {
                        width: 70%;
                        height: auto;
                        float: left;
                        color: #6D617C;
                        font-size: 16px;
                        margin-bottom: 3px;
                        span{
                          width: 100%;
                        height: auto;
                        float: left;
                        color: #a1a1a1;
                        font-size: 13px;
                        }
                    }
                    .dash_perfomencers_amnt {
                      width: 30%;
                      height: auto;
                      float: right;
                      font-size: 16px;
                      color: #656565;
                      text-align: right; 
                  }
                }
              }
             
            }

          }/**main_cnt*/
         
      }
    }
}

.left_navigation_fold {
  .cntr_main_cnt_sc{   padding-left: 100px !important; }
  .left_navigation_sc{
    width: 80px !important;
    
  .submenu{
    width: 150px  !important;
    height: auto !important;
    position: absolute !important;
    left:60px !important;
    top:30px !important;
    background-color: #fff !important;
    // display: none !important;
    transition: opacity 0s ease-out !important;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.3);
    li{margin-bottom: 5px !important;}
  }
  li{
    margin-bottom: 30px !important;
    strong{display: none !important;}
    a{
      .material-icons-outlined{position: static !important;}
    }
   &:hover strong{
    display: block !important;
    position: absolute !important;
    left: 24px !important;
    width: 150px !important;
    padding-left: 20px !important;
    background-color: #fff !important;
    box-shadow: 0px -12px 15px rgba(0, 0, 0, 0.2); 
    z-index: 10 !important;
   }
  }
  .arrow_mn{display: none !important;}
  
  .active {
    a {margin-bottom: 0 !important;
      
      strong{
        left:35px !important;
        top: 15px !important;
      }
     span{
        width: 60px !important;
        height: 50px !important;
        line-height: 50px !important;
      &:after{
        left: 14px !important;
        top: 45px !important;
      }
     }
    }
  }  
  }
  
}
.lst_box_quick_select{
  width: 100%;
  height: auto;
  float: left;
  .lst_box_quick_select_btn{
    position: absolute;
    width: 4px;
    font-size: 22px;
    color: #242424;
    right: 5px;
    top: -3px;
    line-height: 6px;
    word-break: break-word;
    padding: 6px 15px;
    background-color: transparent;
    border: 0;
    z-index: 8;
  }
  
}
.show{
  .dropdown-menu {display: block !important;right: 10px;top: 33px;}
}
.dropdown-item{font-size: 14px;}

.pagetTileText{
  margin-top:30px;
}

.arrow_main_pg{
  margin-top: -9px;
  @media only screen and (max-width:991px){
    margin-top: 0px;
  }
}
 
@media (max-width:1500px) {
  .main_contant_sec  .dashboard_rank_cnt_sc { width: 40% !important;}
  .main_contant_sec .dashboard_rank {width: 60% !important;}
}
@media (max-width:1386px) {
  .resp-tabs-list li{padding: 5px 7px !important;}
  .listing_box_cnout{    font-size: 19px !important;}
  .left_navigation_fold .cntr_main_cnt_sc { padding-left: 70px !important;}
  .listing_box_sec{padding: 10px !important;}
  .dashboard_rank_box span{font-size: 12px !important;}
  .listing_box_ico_rnd{    margin-top: 20px !important; }
} 
@media (max-width:1265px) {
  .profilesoc_share_bx_ico {width: 25px !important;height: 25px !important;line-height: 21px !important;}
  .mid_flll_wdth{width: 100% !important;}
  .list_boxes_main{margin-bottom: 15px;height: auto !important;}
  .dash_member_join_rank_sec{grid-template-columns: repeat(auto-fit, minmax(100%, 1fr)) !important; gap: 0 !important}
  .dashbord_earning_expence{grid-template-columns: repeat(auto-fit, minmax(100%, 1fr)) !important; gap: 0 !important}
  .dashboard_pannel_box{height: auto !important;}
  .dashbord_earning_expence{margin-top: 0 !important;}
  .dash_member_join_rank_sec{margin-top: 0 !important;}
  .list_boxes_main{margin-bottom: 0 !important;}
}
@media (max-width:991px) {
  .profile_other_box{border: 0 !important;}
  .profile_main_sec{border: 0 !important;}
  .profile_soc_share_sec{border: 0 !important;}
}
@media (max-width:768px) {
  .main_contant_sec  .boxes_lst_sec { grid-template-columns: repeat(auto-fit, minmax(100%, 1fr)) !important;}
  .listing_box_sec{text-align: center;  }
  .listing_box_ico_rnd{display: inline-block;float: none !important;}
  // .menubartogglebtn{display: none;}
  .notification_msg_box{z-index: 99 !important;right: -90px !important; }
  .top_user_acc_sec button{font-size: 0;}
.top_user_acc_sec button span{font-size: 15px;}
.logo_sec{width: 150px !important;}
}
@media (max-width:500px) {
  .notification_msg_box{width: 260px !important;transform: translate(67px, 51px) !important;}
.top_notification_sec {width: 90px !important;padding-right: 0; }
.top_notification_ico{   margin-right: 13% !important;}
.profile_main_sec{padding-left: 0px !important; padding-top: 80px;}
.profile_main_img_bx {  width: 70px !important;;height: 70px !important;;left: 0px !important;;}
.topbar_section .logo_sec { width: 140px !important;}
.top_notification_user_sec{position: absolute;right: 0;}
.profilesoc_share_bx {  width: 100% !important;}
.dashboard_main_cnt{padding: 10px !important;}
.dashboard_pannel_box{padding: 10px !important;}
.dashboard_new_member_id_date{width: 100% !important;}
.dashboard_new_member_name{width: 100% !important;}
.dashboard_new_member_date_sc{width: 100% !important;text-align: left !important;}
.dashboard_new_member_amnt{width: 100% !important;text-align: left !important;}
.dashboard_new_member_name{font-weight: 600 !important;}
.spouncer_cnt_boxes {  width: 49% !important;}
.earning-tabs-name span{float: left !important;width: 100%;}
.main_contant_sec .dashboard_rank { width: 97% !important;}
.main_contant_sec .dashboard_rank_cnt_sc { width: 100% !important;  margin-top: 14px !important;}
h2.resp-accordion{border: 0 !important; background-color: transparent !important;}
.dash_perfomencers_name { width:100% !important;}
.dash_perfomencers_amnt {width: 100% !important;text-align: left !important;}
.dash_perfomencers_name span{    font-size: 13px}
h2.resp-accordion{padding: 12px 5px;}
}

.theme-dark .sidebar-icon-only .main_contant_sec .topbar_section .logo_sec .dark_mode_menubartogglebtn span {
  background-color: transparent !important;
}

.theme-dark .sidebar-icon-only .main_contant_sec .topbar_section .logo_sec .dark_mode_menubartogglebtn span::before {
  transform: rotate(140deg) !important;
  bottom: 20px !important;
  width: 25px !important;
}

.theme-dark .sidebar-icon-only .main_contant_sec .topbar_section .logo_sec .dark_mode_menubartogglebtn span::after {
  bottom: 20px !important;
  width: 25px !important;
  transform: rotate(46deg) !important;
}

/******************** end of dark mode ***************************/