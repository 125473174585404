@import '../../style/custonVariable';
.mailboxMainContent{
    padding: 15px;
    width: 100%;
    .leftContent{
        padding: 0;
    }
    .leftPanel{
        left: 0;
        background-color: #e4eaec;
        display: block;
        padding: 0;
        height: 85vh;
        border-right: 1px solid #d4d6d6;
        @media (max-width: 991px) {
            height: auto;
        }
        @media (min-width: 768px) {
            &::before{
                content: "";
                position: absolute;
                top: 0;
                bottom: 0;
                z-index: -1;
                width: inherit;
                background-color: inherit;
                border: inherit;
            }
        }
        .wrapper{
            padding: 15px;
            background-color: #edf1f2;
            .composeButton{
                background-color: $color-primary;
                height: $input-height;
                border-color: $color-primary;
            }
        }
        .navLink{
            color: $color-black !important;
        }
        .navigation{
            width: 100%;
            padding: 15px;
        }
        
    }
    .rightSide{
        padding-left: 0;
        @media (max-width: 991px) {
            padding-right: 0;
        }
        .headerContent{
            background-color: #f6f8f8;
            border-bottom: 1px solid #dee5e7;
            padding: 15px;
            .btnGroup{
                button{
                    background-color: #fff;
                    border-color: rgba(0,0,0,0.1);
                    padding: 6px 12px;
                    line-height: 1.5;
                    i{
                        color: #fff!important;
                    }
                }
            }
        }
        .InboxList{
            margin-top: -1px;
            border-radius: 0;
            height: 78vh;
            overflow: auto;
            min-height: 400px;
            @media (max-width: 991px) {
                height: auto;
                min-height: inherit;
            }
            .listItems{
                padding-top: 15px;
                padding-bottom: 15px;
                border-left-width: 3px;
                border-left-color: #ff01f7;
                display: grid;
                grid-template-columns: 1fr 15fr 3fr;
                grid-gap: $tiles-grid-gap;
                .profileimage{
                    display: inline-block;
                    width: 50px;
                    cursor: pointer;
                    img{
                        max-width: 100%;
                        vertical-align: middle;
                        border-radius: 500px;
                        height: 40px;
                        width: 40px;
                    }
                }
                .dateActions{
                    padding-top: 25%;
                    font-size: 14px;
                    color: #58666e;
                    float: right !important;
                    cursor: pointer;
                    .floatRight{
                        float: right;
                        .deletemail{
                            color: #f05050;
                            padding: 1px 6px
                        }
                    }
                }
                .mailDetails{
                    display: block;
                    overflow: hidden;
                    cursor: pointer;
                    .badgePrimary{
                        background-color: $color-primary;
                        margin-left: 10px;
                        padding: .2em .6em .3em;
                    }
                }
            }
            .noListItems{
                padding-top: 15px;
                padding-bottom: 15px;
                border-left-width: 3px;
                border-left-color: #ff01f7;
            }
        }
        .ViewMailContent{
            padding: 15px;
            border-bottom: 1px solid #dee5e7;
            .mailPanel{
                border-color: #dee5e7;
                border-radius: 2px;
                margin-bottom: 20px;
                background-color: #fff;
                border: 1px solid transparent;
                box-shadow: 0 1px 1px rgba(0, 0, 0, .05);
                .panelHeading{
                    background-color: #f6f8f8;
                    border-radius: 2px 2px 0 0;
                    padding: 0;
                    border-bottom: 1px solid transparent;
                    cursor: pointer;
                    .panelHeadingWrapper{
                        // margin-bottom: 30px;
                        padding: 15px;
                        .profileImage{
                            display: inline-block;
                            width: 34px;
                            margin-right: 10px;
                            vertical-align: middle;
                        }
                    }
                }
                .mailMessage{
                    word-break: break-all;
                }
            }
            .replyContent{
                position: static;
                // position: fixed;
                // bottom: 6%;
                // width: 56%;   
                position: relative;
                // bottom: 18px;
                bottom: 6%;
                border: 1px solid #dee5e7;
                border-radius: 2px;
                margin-bottom: 20px;
                // background-color: #fff;
                -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, .05);
                box-shadow: 0 1px 1px rgba(0, 0, 0, .05);
                .replyMailFixed{
                    background-color: #1c2b36c9;
                    color: #fff;
                    border-radius: 2px 2px 0 0;
                    padding: 10px 15px;
                    border-bottom: 1px solid transparent;
                }
            }
        }
        .composeMailContent{
            margin-bottom: 0!important;
            margin-top: -1px;
            border-radius: 0;
            .listItems{
                border-radius: 0!important;
                padding-top: 15px;
                padding-bottom: 15px;
                .content{
                    padding: 15px;
                    text-align: left;
                    width: 100%;
                    .required::after{
                        content: "*";
                        color: red;
                        padding-left: 5px;
                    }
                    .type{
                        display: inline-flex;
                        .radiolabel{
                            padding-right: 20px;
                        }
                        .radioButton{
                            cursor: pointer;
                            margin-right: 10px;
                        }
                    }
                }
            }
        }
    }
}

//common 
.formControl{
    font-size: 14px;
    box-shadow: none;
    border-color: #cfdadd;
    height: 34px;
}
.isInvalid{
    margin-top: -1px;
    color: #a94442;
}
.Editor{
    background-color: rgb(255, 255, 255);
    border-collapse: separate;
    border-color: rgb(221, 221, 221);
    border-style: solid;
    border-width: 1px;
    clear: none;
    float: none;
    margin: 0px;
    outline: rgb(88, 102, 110) none 0px;
    outline-offset: 0px;
    padding: 10px;
    position: static;
    inset: auto;
    z-index: auto;
    vertical-align: baseline;
    text-align: start;
    box-shadow: none;
    border-radius: 0px;
    width: 100%;
    height: 125px !important;
}
.sendButton{
    background-color: $color-primary;
    height: $input-height;
    border-color: $color-primary;
    &:hover{
        background-color: $color-primary;
    }
}
.inboxBadge{
    font-size: 100%;
    background-color: #ff01f7;
    float: right !important;
    color: #fff;
}


/******************************************* dark mode ********************************************/ 

.mailboxMainContent{
    padding: 15px;
    width: 100%;
    .leftContent{
        padding: 0;
    }
    .dark_mode_leftPanel{
        left: 0;
        background-color: rgba(228,234,236,.050980392156862744) !important;
        display: block;
        padding: 0;
        height: 85vh;
        border-right: 1px solid rgba(212,214,214,.14901960784313725) !important;
        border-bottom: 1px solid hsla(0,0%,96.9%,.10980392156862745);
        @media (max-width: 991px) {
            height: auto;
        }
        @media (min-width: 768px) {
            &::before{
                content: "";
                position: absolute;
                top: 0;
                bottom: 0;
                z-index: -1;
                width: inherit;
                background-color: inherit;
                border: inherit;
            }
        }
        .dark_mode_wrapper{
            padding: 15px;
            //background-color: #edf1f2;
            .composeButton{
                background-color: $color-primary;
                height: $input-height;
                border-color: $color-primary;
            }
        }
        .navLink{
            color: $color-black !important;
        }
        .navigation{
            width: 100%;
            padding: 15px;
        }
    }
    .rightSide{
        padding-left: 0;
        @media (max-width: 991px) {
            padding-right: 0;
        }
        .dark_mode_headerContent{
            background-color: rgba(246,248,248,.14901960784313725) !important;
            border-bottom: 1px solid rgba(222,229,231,.2196078431372549) !important;
            padding: 15px;
            .btnGroup{
                button{
                    color:white !important;
                    background-color: #fff;
                    border-color: rgba(0,0,0,0.1);
                    padding: 6px 12px;
                    line-height: 1.5;
                    i{
                        color: #fff !important;
                    }
                }
            }
        }
        .dark_mode_InboxList{
            margin-top: -1px;
            border-radius: 0;
            height: 78vh;
            overflow: auto;
            min-height: 400px;
            @media (max-width: 991px) {
                height: auto;
                min-height: inherit;
            }
            .listItems{
                padding-top: 15px;
                padding-bottom: 15px;
                border-left-width: 3px;
                //border-left-color: #23b7e5;
                display: grid;
                grid-template-columns: 1fr 15fr 3fr;
                grid-gap: $tiles-grid-gap;
                .profileimage{
                    display: inline-block;
                    width: 50px;
                    cursor: pointer;
                    img{
                        max-width: 100%;
                        vertical-align: middle;
                        border-radius: 500px;
                        height: 40px;
                        width: 40px;
                    }
                }
                .dateActions{
                    padding-top: 25%;
                    font-size: 14px;
                    color: #58666e;
                    float: right !important;
                    cursor: pointer;
                    .floatRight{
                        float: right;
                        .deletemail{
                            color: #f05050;
                            padding: 1px 6px
                        }
                    }
                }
                .mailDetails{
                    display: block;
                    overflow: hidden;
                    cursor: pointer;
                    .badgePrimary{
                        background-color: $color-primary;
                        margin-left: 10px;
                        padding: .2em .6em .3em;
                    }
                }
            }
            .noListItems{
                padding-top: 15px;
                padding-bottom: 15px;
                border-left-width: 3px;
                border-left-color: #23b7e5;
            }
        }
        .ViewMailContent{
            padding: 15px;
            border-bottom: 1px solid #dee5e7;
            .dark_mode_mailPanel{
                //border-color: #dee5e7;
                border-radius: 2px;
                margin-bottom: 20px;
                //background-color: #fff;
                border: 1px solid transparent;
                box-shadow: 0 1px 1px rgba(0, 0, 0, .05);
                .dark_mode_panelHeading{
                    background-color: rgba(246,248,248,.0196078431372549) !important;
                    border-radius: 2px 2px 0 0;
                    padding: 0;
                    border-bottom: 1px solid transparent;
                    cursor: pointer;
                    .panelHeadingWrapper{
                        // margin-bottom: 30px;
                        padding: 15px;
                        .profileImage{
                            display: inline-block;
                            width: 34px;
                            margin-right: 10px;
                            vertical-align: middle;
                        }
                    }
                }
                .dark_mode_mailMessage{
                    word-break: break-all;
                    p{
                        color:#fff !important;
                    }
                }
            }
            .replyContent{
                position: static;
                // position: fixed;
                // bottom: 6%;
                // width: 56%;   
                position: relative;
                // bottom: 18px;
                bottom: 6%;
                border: 1px solid #dee5e7;
                border-radius: 2px;
                margin-bottom: 20px;
                // background-color: #fff;
                -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, .05);
                box-shadow: 0 1px 1px rgba(0, 0, 0, .05);
                .replyMailFixed{
                    background-color: #1c2b36c9;
                    color: #fff;
                    border-radius: 2px 2px 0 0;
                    padding: 10px 15px;
                    border-bottom: 1px solid transparent;
                }
            }
        }
        .composeMailContent{
            margin-bottom: 0!important;
            margin-top: -1px;
            border-radius: 0;
            .listItems{
                border-radius: 0!important;
                padding-top: 15px;
                padding-bottom: 15px;
                .content{
                    padding: 15px;
                    text-align: left;
                    width: 100%;
                    .required::after{
                        content: "*";
                        color: red;
                        padding-left: 5px;
                    }
                    .type{
                        display: inline-flex;
                        .radiolabel{
                            padding-right: 20px;
                        }
                        .radioButton{
                            cursor: pointer;
                            margin-right: 10px;
                        }
                    }
                }
            }
        }
    }
}

//common 
.formControl{
    font-size: 14px;
    box-shadow: none;
    border-color: #cfdadd;
    height: 34px;
}
.isInvalid{
    margin-top: -1px;
    color: #a94442;
}
.Editor{
    background-color: rgb(255, 255, 255);
    border-collapse: separate;
    border-color: rgb(221, 221, 221);
    border-style: solid;
    border-width: 1px;
    clear: none;
    float: none;
    margin: 0px;
    outline: rgb(88, 102, 110) none 0px;
    outline-offset: 0px;
    padding: 10px;
    position: static;
    inset: auto;
    z-index: auto;
    vertical-align: baseline;
    text-align: start;
    box-shadow: none;
    border-radius: 0px;
    width: 100%;
    height: 125px !important;
}
.sendButton{
    background-color: $color-primary;
    height: $input-height;
    border-color: $color-primary;
    &:hover{
        background-color: $color-primary;
    }
}
.inboxBadge{
    font-size: 100%;
    background-color: #ff01f7;
    float: right !important;
    color: #fff;
}

.dark_mode_Editor{
    background-color: #112237 !important;
    border-collapse: separate;
    border-color: rgb(221, 221, 221);
    border-style: solid;
    border-width: 1px;
    clear: none;
    float: none;
    margin: 0px;
    outline: rgb(88, 102, 110) none 0px;
    outline-offset: 0px;
    padding: 10px;
    position: static;
    inset: auto;
    z-index: auto;
    vertical-align: baseline;
    text-align: start;
    box-shadow: none;
    border-radius: 0px;
    width: 100%;
    height: 125px !important;
}

/*************************************** end of dark mode ****************************************/